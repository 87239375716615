import React, { useState } from "react";
import Button from "@mui/material/Button";
import moment from "moment";
import "./App.css";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Input from "@mui/material/Input";
import AutocompleteComponent from "react-google-autocomplete";
import LoadingModal from "./LoadingModal";

function formatAMPM(date: any) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function getRndInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function App() {
  const defaultTime = new Date();
  defaultTime.setHours(defaultTime.getHours() + 2);
  defaultTime.setMinutes(0);
  const [origin, setOrigin] = useState(null);
  const [targetTime, setTargetTime] = useState(defaultTime);
  const [secondsToDestination, setSecondsToDestination] = useState<
    null | number
  >(null);
  const [destination, setDestination] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  return (
    <div className="App" style={{ padding: "30px" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <img src="/man_running.png" width="160px" alt="man running logo" />
        <img
          src="/logo.png"
          alt="Floodsy Time logo"
          style={{
            width: "245px",
            display: "block",
            margin: "0 auto",
            marginBottom: "30px",
          }}
        />
        {(!secondsToDestination || showLoadingModal) && (
          <>
            <p>
              Sick of always showing up late and being that guy? Calculate
              exactly what time you need to leave!
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Input
                fullWidth
                color="secondary"
                placeholder="Where are you leaving from?"
                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                  <AutocompleteComponent
                    apiKey="AIzaSyDL_vzNUd0oLRBFLu27-rxuPgGdAGNPqA4"
                    {...props}
                    onPlaceSelected={(place) => {
                      setOrigin(place);
                      console.log(place);
                    }}
                    // @ts-ignore
                    defaultValue={origin?.formatted_address}
                    options={{
                      types: [],
                      componentRestrictions: {
                        country: "ca",
                      },
                    }}
                  />
                )}
              />
              <Input
                fullWidth
                id="wtf"
                color="secondary"
                style={{
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
                placeholder="Where are you headed?"
                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                  <AutocompleteComponent
                    apiKey="AIzaSyDL_vzNUd0oLRBFLu27-rxuPgGdAGNPqA4"
                    {...props}
                    onPlaceSelected={(place) => {
                      setDestination(place);
                      console.log(place);
                    }}
                    // @ts-ignore
                    defaultValue={destination?.formatted_address}
                    options={{
                      types: [],
                      componentRestrictions: {
                        country: "ca",
                      },
                    }}
                  />
                )}
              />
              <p>What time do you need to be there?</p>
              <MobileTimePicker
                onChange={function (date: any): void {
                  console.log("new value", date);
                  setTargetTime(date);
                }}
                value={targetTime}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button
                variant="contained"
                size="large"
                style={{
                  textTransform: "none",
                  marginTop: "10px",
                }}
                disabled={!origin || !destination}
                onClick={() => {
                  setShowLoadingModal(true);
                  //@ts-ignore
                  const service = new google.maps.DistanceMatrixService();

                  const request = {
                    // @ts-ignore
                    origins: [origin.formatted_address],
                    // @ts-ignore
                    destinations: [destination.formatted_address],
                    //@ts-ignore
                    travelMode: google.maps.TravelMode.DRIVING,
                    //@ts-ignore
                    unitSystem: google.maps.UnitSystem.METRIC,
                    avoidHighways: false,
                    avoidTolls: false,
                  };

                  //@ts-ignore
                  service
                    .getDistanceMatrix(request)
                    .then((res: any) =>
                      setSecondsToDestination(
                        res.rows[0].elements[0].duration.value
                      )
                    );
                }}
              >
                Fire it up, Dale!
              </Button>
            </div>
          </>
        )}
        {showLoadingModal && (
          <LoadingModal onComplete={() => setShowLoadingModal(false)} />
        )}
        {secondsToDestination && !showLoadingModal && (
          <div>
            <h1>
              {" "}
              You should leave at:{" "}
              {formatAMPM(
                moment(targetTime)
                  .subtract(secondsToDestination * 1000)
                  .subtract(getRndInteger(30, 60) * 60 * 1000)
                  .toDate()
              )}
            </h1>
            It only would take a normal person{" "}
            {moment.duration(secondsToDestination * 1000).humanize()} to get
            there but remember, there's a few things that might make you late:
            <br />
            <br />
            • Getting stuck in traffic
            <br />
            • Deciding which car to take
            <br />
            • Chasing down Gordie
            <br />• A complete lack of respect for other people's time
            <br />
            <br />
            <Button
              variant="contained"
              size="large"
              style={{
                textTransform: "none",
                marginTop: "10px",
              }}
              disabled={!origin || !destination}
              onClick={() => {
                setSecondsToDestination(null);
              }}
            >
              Restart
            </Button>
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
}

export default App;
