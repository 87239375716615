import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

type Props = {
  onComplete: () => void;
};

const messages = [
  "Checking Essex County traffic report...",
  "Checking train schedules...",
  "Checking chances of catching the sips...",
];

const LoadingModal = ({ onComplete }: Props) => {
  // Create a curr state indicating what's the current slide's index
  const [curr, setCurr] = React.useState(0);
  const { length } = messages;

  const goToNext = () => {
    if (curr === length - 1) {
      onComplete();
      return;
    }

    // Check if we've reached the final slide in the array
    // If so, go back to 0, else curr + 1
    setCurr(curr === length - 1 ? 0 : curr + 1);
  };

  // useEffect will run at every re-render
  React.useEffect(() => {
    // At every render, set a new timeout to go to the next slide
    setTimeout(goToNext, 3000);
    // And, when unmounting <Slider />, clear the timeout
    // See the reactjs.org docs on hooks for more info
    return function () {
      // @ts-ignore
      clearTimeout(goToNext);
    };
  });

  return (
    <Modal
      open={true}
      onClose={() => console.log("close")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <img
          src="/flood.png"
          alt="spinning floodsy head"
          style={{ width: "100px", animation: `spin 1.3s linear infinite` }}
        />

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {messages[curr]}
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
